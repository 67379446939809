import React, { useState, useEffect } from 'react';
import { TrashIcon, PencilSquareIcon, DocumentIcon, ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Switch, Field, Label } from '@headlessui/react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { cloneDeep } from 'lodash';
import {
  useGetSqlPathsQuery,
  useLazyGetModelQuery,
  useSetModelMutation,
  useDeleteModelMutation,
  useGenerateDbtSourcesMutation,
  useValidateModelMutation,
  useGetDefaultDatabaseQuery,
  useFormatSqlMutation,
} from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import { selectDataProductName } from 'features/data-product-builder/reducers/builderSlice';
import { selectSnowflakeAccount, selectSelectedDatabase, setSelectedDatabase } from 'app/snowflakeSlice';
import {
  selectModels,
  setModels,
  selectReviewedByInformationArchitect,
  setReviewedByInformationArchitect,
  selectHasIncorrectFilenames,
  setHasIncorrectFilenames,
} from '../reducers/astraZenecaSlice';
import StepWrapper from 'components/StepWrapper';
import AzDatabaseSelector from '../components/AzDatabaseSelector';
import DropdownSingleselect from 'components/DropdownSingleselect';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import InformationTooltip from 'components/InformationTooltip';
import ModelErrorIcon from '../components/ModelErrorIcon';
import ModelFromViewDialog from '../components/ModelFromViewDialog';
import OutlineButtonWithTooltip from '../components/OutlineButtonWithTooltip';
import { getSnakeCase } from 'utils/formatUtils';
import { classNames } from 'utils/styleUtils';
import type { ISqlModel } from '../types/sqlBuilderTypes';

export interface SqlModelBuilderStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SqlModelBuilderStep(props: SqlModelBuilderStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const models = useAppSelector(selectModels);
  const hasIncorrectFilenames = useAppSelector(selectHasIncorrectFilenames);
  const dataProductName = useAppSelector(selectDataProductName);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const reviewedByInformationArchitect = useAppSelector(selectReviewedByInformationArchitect);

  const [filename, setFilename] = useState<string | undefined>();
  const [selectedModelIndex, setSelectedModelIndex] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [isValidateAllCompleted, setIsValidateAllCompleted] = useState<boolean>(false);
  const [validationInProgress, setValidationInProgress] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  const [sqlPaths, setSqlPaths] = useState<string[] | undefined>();
  const [isOptimizationInProgress, setIsOptimizationInProgress] = useState<boolean>(false);

  const [setModel] = useSetModelMutation();
  const [deleteModel] = useDeleteModelMutation();
  const [generateDbtSources] = useGenerateDbtSourcesMutation();
  const [validateModel] = useValidateModelMutation();
  const [formatSql] = useFormatSqlMutation();

  // True if we are waiting to fetch the SQL for the currently selected model
  const [isLoadingCurrentModel, setIsLoadingCurrentModel] = useState<boolean>(false);

  const [isModelFromViewDialogOpen, setIsModelFromViewDialogOpen] = useState<boolean>(false);

  const { data: defaultDatabaseFeatureBranch, isError: isErrorDefaultDatabase } = useGetDefaultDatabaseQuery({
    projectPath,
    branch,
  });

  const {
    data: sqlPathsFeatureBranch,
    isLoading,
    isError: isErrorSqlPaths,
  } = useGetSqlPathsQuery({ projectPath, branch });

  const { data: defaultDatabaseMain } = useGetDefaultDatabaseQuery({
    projectPath,
    branch: 'main',
  });

  const { data: sqlPathsMain, isLoading: isLoadingSqlPathsMain } = useGetSqlPathsQuery({ projectPath, branch: 'main' });

  const [getModel] = useLazyGetModelQuery();

  const modelFileNamePattern = /^[vmi]_[a-zA-Z0-9_]+__[a-zA-Z0-9_]+\.sql$/;

  useEffect(() => {
    if (defaultDatabaseFeatureBranch !== undefined && defaultDatabaseFeatureBranch !== '' && selectedDatabase === '') {
      dispatch(setSelectedDatabase(defaultDatabaseFeatureBranch));
    } else if (isErrorDefaultDatabase && defaultDatabaseMain !== undefined && defaultDatabaseMain !== '') {
      dispatch(setSelectedDatabase(defaultDatabaseMain));
    }
  }, [defaultDatabaseFeatureBranch, isErrorDefaultDatabase, defaultDatabaseMain]);

  useEffect(() => {
    if (sqlPathsFeatureBranch !== undefined) {
      setSqlPaths(sqlPathsFeatureBranch);
    } else if (isErrorSqlPaths && sqlPathsMain !== undefined) {
      setSqlPaths(sqlPathsMain);
    }
  }, [sqlPathsFeatureBranch, isErrorSqlPaths, sqlPathsMain]);

  useEffect(() => {
    if (
      sqlPaths === undefined &&
      sqlPathsFeatureBranch === undefined &&
      sqlPathsMain === undefined &&
      !isLoading &&
      !isLoadingSqlPathsMain &&
      models === undefined
    ) {
      dispatch(setModels([]));
    }

    if (sqlPaths !== undefined && models === undefined) {
      const filteredSqlPaths = sqlPaths?.filter((sqlPath) => modelFileNamePattern.test(sqlPath.split('/').pop() ?? ''));

      dispatch(setHasIncorrectFilenames(sqlPaths.length !== filteredSqlPaths.length));

      dispatch(
        setModels(
          filteredSqlPaths.map((sqlPath) => {
            const nameSplit = sqlPath.split('/')[sqlPath.split('/').length - 1].split('__');
            const schemaName = nameSplit[0].replace(/^v_|^m_|^i_/, '');
            const modelName = nameSplit[1].replace('.sql', '').replace('.sql', '');

            return {
              name: modelName,
              schema: schemaName,
              path: sqlPath,
              sql: undefined,
              previousSql: undefined,
              materialization: sqlPath.includes('/materialized/')
                ? 'materialized'
                : sqlPath.includes('/virtualized/')
                ? 'virtualized'
                : 'incremental',
              sqlValidationStatus: 'unevaluated',
              modelNameValidationStatus: 'unevaluated',
            };
          }),
        ),
      );
    }
  }, [sqlPaths, sqlPathsFeatureBranch, sqlPathsMain, isLoading, isLoadingSqlPathsMain]);

  useEffect(() => {
    setIsLoadingCurrentModel(false);
    if (
      models === undefined ||
      models.length === 0 ||
      selectedModelIndex === undefined ||
      selectedModelIndex >= models.length
    ) {
      return;
    }

    setFilename(undefined);

    if (models[selectedModelIndex].sql === undefined) {
      setIsLoadingCurrentModel(true);
      getModel(
        {
          projectPath,
          branch,
          dbtPath: models[selectedModelIndex].path,
        },
        true,
      )
        .unwrap()
        .then((response) => {
          const newModels = models.map((model, index) => {
            if (index === selectedModelIndex) {
              return {
                ...model,
                sql: response,
              };
            }
            return model;
          });
          dispatch(setModels(newModels));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoadingCurrentModel(false);
        });
    }
  }, [selectedModelIndex]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files !== null) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target !== null) {
          onChangeModelSql(event.target.result as string, true);
        }
      };
      reader.readAsText(e.target.files[0]);
      setFilename(e.target.files[0].name);
      e.target.value = '';
    }
  };

  const onCreateNewModel = (modelName?: string, modelSql?: string): void => {
    if (models === undefined) {
      return;
    }

    let newModelIndex = 1;
    const newModelPattern = /^object_(?<number>\d+)$/;
    for (const model of models) {
      const match = model.name.match(newModelPattern);
      if (match !== undefined) {
        const number = match?.groups?.number;
        if (number !== undefined) {
          newModelIndex = Math.max(parseInt(number) + 1, newModelIndex);
        }
      }
    }

    setSelectedModelIndex(models.length);
    const schemaName = dataProductName.replaceAll(' ', '_');
    dispatch(
      setModels([
        ...models,
        {
          name: modelName ?? `object_${newModelIndex}`,
          schema: schemaName,
          path: `dataops/modelling/models/virtualized/${getSnakeCase(dataProductName)}/${
            modelName !== undefined
              ? `v_${schemaName}__${modelName}.sql`
              : `v_${schemaName}__object_${newModelIndex}.sql`
          }`,
          sql: modelSql ?? '',
          previousSql: undefined,
          materialization: 'virtualized',
          sqlValidationStatus: 'unevaluated',
          modelNameValidationStatus: 'unevaluated',
        },
      ]),
    );

    setIsValidateAllCompleted(false);
  };

  const onChangeModelName = (name: string): void => {
    if (models === undefined || selectedModelIndex === undefined) {
      return;
    }

    const newModels = cloneDeep(models);
    newModels[selectedModelIndex].name = name;
    const path = newModels[selectedModelIndex].path.split('/');
    const schemaName = path[path.length - 1].split('__')[0].replace(/^v_|^m_|^i_/, '');
    path[path.length - 1] = `${
      newModels[selectedModelIndex].materialization === 'virtualized'
        ? 'v_'
        : newModels[selectedModelIndex].materialization === 'materialized'
        ? 'm_'
        : 'i_'
    }${schemaName}__${name}.sql`;
    newModels[selectedModelIndex].path = path.join('/');
    newModels[selectedModelIndex].modelNameValidationStatus = 'unevaluated';
    dispatch(setModels(newModels));

    setIsValidateAllCompleted(false);
  };

  const onChangeModelSql = (sql: string, resetPreviousSql?: boolean): void => {
    if (models === undefined || selectedModelIndex === undefined) {
      return;
    }

    const newModels = cloneDeep(models);
    if (resetPreviousSql === true) {
      newModels[selectedModelIndex].previousSql = undefined;
    } else {
      newModels[selectedModelIndex].previousSql = newModels[selectedModelIndex].sql;
    }
    newModels[selectedModelIndex].sql = sql;
    newModels[selectedModelIndex].sqlValidationStatus = 'unevaluated';
    dispatch(setModels(newModels));

    if (failed && errorMessages[0] === `Model ${models[selectedModelIndex].name} is invalid`) {
      setFailed(false);
      setErrorMessages([]);
    }

    setIsValidateAllCompleted(false);
  };

  const onChangeMaterialization = (materialization: string): void => {
    if (models === undefined || selectedModelIndex === undefined) {
      return;
    }

    const newModels = cloneDeep(models);
    newModels[selectedModelIndex].materialization = materialization as 'materialized' | 'virtualized' | 'incremental';
    const path = newModels[selectedModelIndex].path.split('/');
    path[path.length - 3] = materialization;
    if (materialization === 'materialized') {
      newModels[selectedModelIndex].path = path.join('/').replace(/\/v_|\/i_/, '/m_');
    } else if (materialization === 'virtualized') {
      newModels[selectedModelIndex].path = path.join('/').replace(/\/m_|\/i_/, '/v_');
    } else if (materialization === 'incremental') {
      newModels[selectedModelIndex].path = path.join('/').replace(/\/m_|\/v_/, '/i_');
    }
    dispatch(setModels(newModels));

    setIsValidateAllCompleted(false);
  };

  const onDeleteModel = (model: ISqlModel): void => {
    if (models === undefined) {
      return;
    }

    const indexOfDeletedModel = models.findIndex((m) => m.path === model.path);
    if (selectedModelIndex === indexOfDeletedModel) {
      setSelectedModelIndex(undefined);
    } else if (selectedModelIndex !== undefined && selectedModelIndex > indexOfDeletedModel) {
      setSelectedModelIndex(selectedModelIndex - 1);
    }

    dispatch(setModels(models.filter((m) => m.path !== model.path)));
  };

  const validateModelSql = async (sql: string): Promise<void> => {
    if (models === undefined || selectedModelIndex === undefined) {
      return;
    }

    setValidationInProgress(true);
    const newModels = cloneDeep(models);

    const nonSelectCommands = ['create', 'drop', 'insert', 'update', 'delete', 'alter', 'truncate', 'grant', 'revoke'];
    if (sql !== undefined && sql !== '') {
      for (const command of nonSelectCommands) {
        const regex = new RegExp(`\\b${command}\\b`, 'i'); // Create a regex with word boundaries
        if (regex.test(sql)) {
          newModels[selectedModelIndex].sqlValidationStatus = 'invalid';
          newModels[selectedModelIndex].sqlValidationErrorMessage = 'The SQL contains a non-SELECT command';
          setValidationInProgress(false);
          dispatch(setModels(newModels));
          return;
        }
      }
    }

    try {
      const res = await validateModel({
        account: snowflakeAccount,
        database: selectedDatabase,
        sql,
      }).unwrap();
      console.log(res);
      newModels[selectedModelIndex].sqlValidationStatus = 'valid';
    } catch (err: any) {
      console.log(err);
      newModels[selectedModelIndex].sqlValidationStatus = 'invalid';
      newModels[selectedModelIndex].sqlValidationErrorMessage = err.data as string;
    }

    dispatch(setModels(newModels));

    setValidationInProgress(false);
  };

  const onCopy = async (): Promise<void> => {
    try {
      if (models !== undefined && selectedModelIndex !== undefined) {
        await navigator.clipboard.writeText(models[selectedModelIndex].sqlValidationErrorMessage as string);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1500);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onValidateAll = async (): Promise<void> => {
    if (models === undefined) {
      return;
    }

    setLoading(true);
    setFailed(false);
    const validationErrors = [];
    const updatedModels = cloneDeep(models);

    const placeholderNamePattern = /^object_[0-9]+$/;
    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (placeholderNamePattern.test(model.name)) {
        validationErrors.push(
          `Model names must adhere to the AZ naming standards. Please correct the name format for ${model.name}`,
        );
        updatedModels[modelIndex].modelNameValidationStatus = 'invalid';
        setFailed(true);
      }
    }

    const modelNamePattern = /^[a-zA-Z0-9_]+$/;
    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (!modelNamePattern.test(model.name)) {
        validationErrors.push(`Model name ${model.name} is not matching the pattern {object_name}`);
        setFailed(true);
        updatedModels[modelIndex].modelNameValidationStatus = 'invalid';
      }
    }

    // If the SQL ends with a semicolon, remove it
    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (model.sql !== undefined && model.sql !== '') {
        let sql = model.sql.trimEnd();
        if (sql.endsWith(';')) {
          sql = sql.replace(/;$/, '');
        }
        updatedModels[modelIndex].sql = sql;
      }
    }

    const nonSelectCommands = ['create', 'drop', 'insert', 'update', 'delete', 'alter', 'truncate', 'grant', 'revoke'];
    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (model.sql !== undefined && model.sql !== '') {
        const sql = model.sql.toLowerCase();
        for (const command of nonSelectCommands) {
          const regex = new RegExp(`\\b${command}\\b`, 'i'); // Create a regex with word boundaries
          if (regex.test(sql)) {
            validationErrors.push(`Model ${model.name} contains a non-SELECT command`);
            setFailed(true);
            updatedModels[modelIndex].sqlValidationStatus = 'invalid';
            updatedModels[modelIndex].sqlValidationErrorMessage = 'The SQL contains a non-SELECT command';
          }
        }
      }
    }

    for (let modelIndex = 0; modelIndex < models.length; modelIndex++) {
      const model = models[modelIndex];
      if (model.sql !== undefined && model.sql !== '') {
        try {
          const res = await validateModel({
            account: snowflakeAccount,
            database: selectedDatabase,
            sql: model.sql,
          }).unwrap();
          console.log(res);
          updatedModels[modelIndex].sqlValidationStatus = 'valid';
        } catch (err: any) {
          console.log(err);
          setFailed(true);
          validationErrors.push(`Model ${model.name} is invalid`);
          updatedModels[modelIndex].sqlValidationStatus = 'invalid';
          updatedModels[modelIndex].sqlValidationErrorMessage = err.data as string;
        }
      } else if (model.sql === '') {
        validationErrors.push(`No SQL provided for model ${model.name}`);
        setFailed(true);
        updatedModels[modelIndex].sqlValidationStatus = 'invalid';
      }
    }

    setLoading(false);
    setErrorMessages(validationErrors);
    dispatch(setModels(updatedModels));

    if (validationErrors.length === 0) {
      setFailed(false);
      setIsValidateAllCompleted(true);
    }
  };

  const onOptimize = (): void => {
    if (models !== undefined && selectedModelIndex !== undefined && selectedModelIndex < models.length) {
      setIsOptimizationInProgress(true);
      const sql = models[selectedModelIndex].sql as string;
      formatSql({ sql })
        .then((res: any) => {
          if (res.data !== undefined) {
            onChangeModelSql(res.data, false);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsOptimizationInProgress(false));
    }
  };

  const onUndoOptimization = (): void => {
    if (
      models !== undefined &&
      selectedModelIndex !== undefined &&
      selectedModelIndex < models.length &&
      models[selectedModelIndex].previousSql !== undefined
    ) {
      onChangeModelSql(models[selectedModelIndex].previousSql as string, true);
    }
  };

  const onContinue = async (): Promise<void> => {
    if (models === undefined) {
      return;
    }

    setLoading(true);
    setErrorMessages([]);
    setFailed(false);

    if (sqlPaths !== undefined) {
      for (const sqlPath of sqlPaths) {
        const model = models.find((m) => m.path === sqlPath);
        if (model === undefined && modelFileNamePattern.test(sqlPath.split('/').pop() ?? '')) {
          const pathSplit = sqlPath.split('/');
          const materialization = pathSplit[pathSplit.length - 3];
          try {
            const res = await deleteModel({
              projectPath,
              branch,
              dbtPath: sqlPath,
              materialization,
              dataProductName,
            }).unwrap();
            console.log(res);
          } catch (err) {
            console.log(err);
            setFailed(true);
            setLoading(false);
            return;
          }
        }
      }
    }

    for (const model of models) {
      if (model.sql !== undefined && model.sql !== '') {
        try {
          const res = await setModel({
            projectPath,
            branch,
            modelPath: model.path,
            materialization: model.materialization,
            dataProductName,
            sql: model.sql,
            defaultDatabase: selectedDatabase,
          }).unwrap();
          console.log(res);
        } catch (err) {
          console.log(err);
          setFailed(true);
          setLoading(false);
          return;
        }
      }
    }

    try {
      const res = await generateDbtSources({
        projectPath,
        branch,
        dataProductName,
        account: snowflakeAccount,
        database: selectedDatabase,
      }).unwrap();
      console.log(res);
    } catch (err) {
      console.log(err);
      setErrorMessages(['Failed to generate dbt sources']);
      setFailed(true);
      setLoading(false);
      return;
    }

    setLoading(false);
    setFailed(false);

    props.onContinue();
  };

  return (
    <StepWrapper
      title="SQL Model Builder"
      subtitle="Transform your SQL queries into powerful, scalable data models with ease"
      onBack={() => props.onBack()}
      onContinue={() => {
        onContinue().catch((err) => console.log(err));
      }}
      isLoading={loading}
      continueDisabled={selectedDatabase === ''}
      {...(!isValidateAllCompleted && {
        customButtonLabel: 'Validate All',
        customButtonAction: () => {
          onValidateAll().catch((err) => console.log(err));
        },
      })}
    >
      <div className="w-full grid grid-cols-2 gap-4 px-8 wide:px-12 max-w-[70rem] mx-[auto]">
        <Field as="div" className="col-span-2 flex items-center justify-center pb-2">
          <Switch
            checked={reviewedByInformationArchitect}
            onChange={() => dispatch(setReviewedByInformationArchitect(!reviewedByInformationArchitect))}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
          >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
            <span
              aria-hidden="true"
              className={classNames(
                reviewedByInformationArchitect ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
              )}
            />
            <span
              aria-hidden="true"
              className={classNames(
                reviewedByInformationArchitect ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Label as="span" className="ml-1 cursor-default flex">
            <span className="text-sm font-medium text-gray-700">Reviewed by Information Architect</span>
          </Label>
        </Field>
        <div className="col-span-2 xl:col-span-1">
          <div className="flex">
            <h3 className="font-semibold text-gray-800">Select a database</h3>
            <InformationTooltip tooltip="Choose the database where the new models will be created" />
          </div>
          <AzDatabaseSelector
            onSelect={(database: string) => dispatch(setSelectedDatabase(database))}
            selectedDatabases={[selectedDatabase]}
            onlyShowDatabases={true}
          />
        </div>
        <div className="col-span-2 xl:col-span-1">
          <div className="flex px-4 mb-2">
            <h3 className="font-semibold text-gray-800 mr-[auto]">Models</h3>
            {selectedDatabase !== '' && (
              <>
                <OutlineButtonWithTooltip
                  label="Model from view"
                  onClick={() => setIsModelFromViewDialogOpen(true)}
                  tooltip="Create a new model from an existing Snowflake view"
                />
                <OutlineButtonWithTooltip
                  label="Model from SQL"
                  onClick={() => onCreateNewModel()}
                  tooltip="Create a new model from an SQL query"
                />
              </>
            )}
            {selectedDatabase === '' && (
              <div className="flex space-x-1">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-600" />
                <div className="text-sm text-yellow-600">Select a database</div>
              </div>
            )}
          </div>
          {hasIncorrectFilenames && (
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-yellow-800">
                    Some models could not be loaded because their filenames are invalid.
                  </p>
                </div>
              </div>
            </div>
          )}
          {models !== undefined && models.length > 0 && (
            <ul role="list" className="divide-y divide-gray-100 cursor-pointer">
              {models?.map((model, modelIndex) => (
                <li
                  key={modelIndex}
                  onClick={() => setSelectedModelIndex(modelIndex)}
                  className={classNames(
                    modelIndex === selectedModelIndex ? 'bg-gray-50' : 'bg-white',
                    'relative flex items-center px-4 py-1 text-gray-700 hover:bg-gray-50 rounded-md',
                  )}
                >
                  <input
                    id="first-option"
                    name="deployment"
                    type="radio"
                    checked={selectedModelIndex === modelIndex}
                    onChange={() => {
                      setValidationInProgress(false);
                      setSelectedModelIndex(modelIndex);
                    }}
                    className="w-3 h-3 rounded border-gray-300 mr-2"
                  />
                  <div
                    className={classNames(
                      model.modelNameValidationStatus === 'invalid' ? 'text-red-600' : 'text-gray-700',
                      'text-sm truncate',
                    )}
                  >
                    {model.name}
                  </div>
                  {model.sqlValidationStatus === 'invalid' && (
                    <ModelErrorIcon tooltip="The SQL query for this model is not valid" />
                  )}
                  <button type="button" className="ml-[auto] rounded-full text-dataops-secondary-blue p-1 shadow-sm">
                    <PencilSquareIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteModel(model);
                    }}
                    className="rounded-full text-dataops-red p-1 shadow-sm"
                  >
                    <TrashIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </li>
              ))}
            </ul>
          )}
          {models !== undefined && models.length === 0 && !isLoading && (
            <div className="mt-4 px-4 text-base text-gray-600">No models</div>
          )}
        </div>
        {models !== undefined &&
          models.length !== 0 &&
          selectedModelIndex !== undefined &&
          selectedModelIndex < models.length && (
            <>
              <div className="col-span-2 xl:col-span-1">
                <div className="flex">
                  <label htmlFor="version" className="block leading-6 text-gray-800 font-semibold">
                    Selected model
                  </label>
                  <InformationTooltip tooltip="Only provide the name of the model, the schema name and the prefix will be added automatically" />
                </div>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                    <input
                      type="text"
                      id="version"
                      name="version"
                      value={models[selectedModelIndex].name}
                      onChange={(e) => onChangeModelName(e.target.value)}
                      autoComplete="modelName"
                      placeholder="Model name"
                      required
                      className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2 xl:col-span-1 pl-3">
                <label htmlFor="version" className="block leading-6 text-gray-800 font-semibold">
                  Materialization
                </label>
                <DropdownSingleselect
                  label=""
                  options={['virtualized', 'materialized', 'incremental']}
                  selected={models[selectedModelIndex].materialization}
                  setSelected={(value: string) => onChangeMaterialization(value)}
                />
              </div>
              <div className="col-span-2 w-3/4 max-w-[50rem] mx-[auto] mt-4">
                <label className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 py-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <input type="file" accept=".sql" onChange={onFileChange} className="hidden" />
                  {filename === undefined && (
                    <span className="flex items-center justify-center text-sm text-gray-500">Upload a SQL file</span>
                  )}
                  {filename !== undefined && (
                    <div className="flex items-center justify-center">
                      <DocumentIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                      <span className="flex items-center justify-center text-sm text-gray-500">{filename}</span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-span-2 text-sm text-gray-600 text-center">or paste the SQL in the field below</div>
              <div className="relative col-span-2 w-3/4 max-w-[50rem] mx-[auto]">
                <div className="rounded-md shadow-sm bg-dataops-primary-blue/10 p-[2px] pl-[1.8rem]">
                  <CodeEditor
                    id="sql"
                    name="sql"
                    language="sql"
                    minHeight={200}
                    data-color-mode="light"
                    value={models[selectedModelIndex].sql}
                    onChange={(e) => {
                      onChangeModelSql(e.target.value, true);
                      setValidationInProgress(false);
                    }}
                    rows={8}
                    required
                    className="bg-transparent rounded-r block w-full border-0 text-sm text-black placeholder:text-gray-400"
                    placeholder={isLoadingCurrentModel ? 'Loading...' : 'Paste your SQL here'}
                    style={{ overflow: 'visible', backgroundColor: 'rgb(246 251 254)' }}
                  />
                </div>
                {models[selectedModelIndex].sql !== '' && (
                  <div className="absolute bottom-2 right-2 flex space-x-1">
                    {!validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'invalid' && (
                      <button
                        type="button"
                        className="rounded-full h-[24px] w-[24px] bg-white flex items-center justify-center ring-1 ring-inset ring-red-600 text-red-500 cursor-pointer hover:text-red-400 hover:ring-red-400"
                        onClick={() => {
                          onCopy().catch((err) => console.log(err));
                        }}
                      >
                        {copySuccess ? (
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        ) : (
                          <ClipboardIcon className="h-4 w-4" aria-hidden="true" />
                        )}
                      </button>
                    )}
                    {(validationInProgress || models[selectedModelIndex].sqlValidationStatus !== 'unevaluated') && (
                      <button
                        type="button"
                        className={classNames(
                          !validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'valid'
                            ? 'border-green-600 cursor-default'
                            : !validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'invalid'
                            ? 'border-red-600 cursor-default'
                            : 'border-dataops-secondary-blue cursor-pointer hover:bg-gray-50',
                          'rounded-full h-[24px] w-[6rem] bg-white border  px-1 py-0 text-sm shadow-sm text-dataops-secondary-blue flex items-center justify-center',
                        )}
                      >
                        {validationInProgress && <span>Validating...</span>}
                        {!validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'valid' && (
                          <span className="text-green-700">Valid</span>
                        )}
                        {!validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'invalid' && (
                          <span className="text-red-500 flex relative">
                            <div>Invalid</div>
                            <div className="absolute right-[-14px]">
                              <InformationTooltip
                                isRed={true}
                                tooltip={models[selectedModelIndex].sqlValidationErrorMessage as string}
                              />
                            </div>
                          </span>
                        )}
                      </button>
                    )}
                    {!validationInProgress && models[selectedModelIndex].sqlValidationStatus === 'unevaluated' && (
                      <OutlineButtonWithTooltip
                        label="Validate"
                        onClick={() => {
                          validateModelSql(models[selectedModelIndex].sql as string).catch((err) => console.log(err));
                        }}
                        tooltip="Validate the SQL query using the provided Snowflake account and database"
                        style="h-[24px] w-[6rem]"
                      />
                    )}
                    <OutlineButtonWithTooltip
                      label={
                        isOptimizationInProgress
                          ? 'Optimizing...'
                          : models[selectedModelIndex].previousSql !== undefined
                          ? 'Undo'
                          : 'Optimize'
                      }
                      onClick={() => {
                        if (models[selectedModelIndex].previousSql === undefined) {
                          onOptimize();
                        } else {
                          onUndoOptimization();
                        }
                      }}
                      tooltip="Format the SQL based on the sqlfluff standards and run an AI-driven optimization"
                      style="h-[24px] w-[6rem]"
                    />
                  </div>
                )}
              </div>
            </>
          )}
        <div className="col-span-2 w-3/4 max-w-[50rem] mx-[auto]">
          <LoadingAndErrorSection
            isLoading={loading}
            isFailed={failed}
            errorMessage={errorMessages}
            hideLoading={true}
          />
        </div>
        {isValidateAllCompleted && !loading && !failed && (
          <div className="col-span-2 w-3/4 max-w-[50rem] mx-[auto] py-1 px-1.5 text-sm rounded bg-green-50 border-2 border-green-500 text-green-600 flex items-center justify-center">
            Validation passed for all models
          </div>
        )}
      </div>
      <ModelFromViewDialog
        open={isModelFromViewDialogOpen}
        setOpen={setIsModelFromViewDialogOpen}
        onCreateNewModel={onCreateNewModel}
      />
    </StepWrapper>
  );
}
